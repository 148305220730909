<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <a-divider />

    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="上级菜单">
        <MenuTreeSelect v-model="formData.pid"></MenuTreeSelect>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input style="width: 400px; margin-bottom: 10px" v-model="formData.name" size="small" placeholder="名称"></el-input>
        <!-- <MapArea :keyword="formData.name" :lat.sync="formData.lat" :lng.sync="formData.lng"></MapArea> -->
      </el-form-item>
      <el-form-item label="排序">
        <el-input style="width: 400px; margin-bottom: 10px" v-model="formData.sort" size="small" placeholder="排序"></el-input>
        <!-- <MapArea :keyword="formData.name" :lat.sync="formData.lat" :lng.sync="formData.lng"></MapArea> -->
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="" @click="$router.back()" size="small">取消</el-button>
        <el-button type="primary" @click="save" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import MenuTreeSelect from './menu-tree-select.vue'
import { getMenuDetail, editMenu, addMenu } from './api'

export default {
  name: 'Detail',

  components: { MenuTreeSelect },

  data() {
    return {
      pid: 0,
      formData: {
        pid: 0,
        name: '', //名称
        sort: 0
      },
      rules: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }]
      }
    }
  },

  computed: {},

  created() {
    // 只传parent_id为新增
    if (this.$route.params.parent_id && this.$route.params.menuId === undefined) this.formData.pid = this.$route.params.parent_id
    // 只传了menuId为编辑
    if (this.$route.params.menuId && this.$route.params.parent_id === undefined) this.apiQueryMenuDetail()
  },
  mounted() {},
  methods: {
    async save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (this.formData.id) {
            await editMenu(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            await addMenu(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        } else {
          return false
        }
      })
    },

    async apiQueryMenuDetail() {
      const res = await getMenuDetail({ id: this.$route.params.menuId })
      this.formData = res
      // if (res.level == 4) {
      //   this.$set(this.formData, 'district_id', res.area_district ? res.area_district.district.id : '')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 70%;

        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
