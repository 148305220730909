import { http } from '@/http/axios.js'

// 列表
export function getMenusList(params) {
  return http({
    url: '/company/guideCity/tree',
    method: 'GET',
    params
  })
}

// 树
export function getMenusTree(params) {
  return http({
    url: '/company/guideCity/tree',
    method: 'get',
    params
  })
}
export function getMenuDetail(params) {
  return http({
    url: `/company/guideCity/detail`,
    method: 'GET',
    params
  })
}

export function editMenu(data) {
  return http({
    url: `/company/guideCity/edit`,
    method: 'post',
    data
  })
}

export function addMenu(data) {
  return http({
    url: `/company/guideCity/add`,
    method: 'POST',
    data
  })
}

export function delMenu(id) {
  return http({
    url: `/company/guideCity/delete`,
    method: 'post',
    data: { id }
  })
}
